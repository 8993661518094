<template>
  <div>
    <!--  材料信息-->
    <div style="width: 100%;margin:  auto;min-height: 80vh;background-color: #FFFFFF">
      <div style=";padding-top: 0.5%">
        <div v-if="GET_BDST === '1'">
          <el-tag effect="dark" size="large" style="margin-right: 0.5%"> 选择工区 </el-tag>
          <el-select v-model="bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 15%" @change="changeMaterial()">
            <el-option
                v-for="item in bdsts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
        <div style="margin-top: 1%;display: flex">
          <el-tag effect="dark" size="large" style="margin-right: 0.5%"> 材料名称 </el-tag>
          <el-input v-model="material_name" placeholder="请输入材料名称" size="small" style="width: 15%" @input="changeMaterial()"/>
          <el-button type="primary" size="small" style="margin-left: 5%" @click="add_material()">新增材料</el-button>
        </div>
      </div>
      <!--  材料列表-->
      <div style="padding-top: 0.5%">
        <el-table :data="materials" style="width: 100%">
          <el-table-column prop="bdst" label="所属工区" width="180" />
          <el-table-column prop="materialName" label="材料名称" width="280" />
          <el-table-column prop="materialModel" label="规格型号" width="280" />
          <el-table-column prop="materialNum" label="当前库存" width="280" />
          <el-table-column label="操作" >
            <template #default="scope">
              <el-button type="primary" @click="update_material(scope.row)"> 修改</el-button>
              <el-button type="danger" @click="remove_material(scope.row)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页-->
      <div v-if="first" style="float: right;margin-top: 20px;">
        <el-pagination v-model:currentPage="pagenum"
                       :small="true"
                       background layout="prev, pager, next" :total="totals">
        </el-pagination>
      </div>
      <div v-if="!first" style="float: right;margin-top: 20px;">
        <el-pagination v-model:currentPage="pagenum1"
                       :small="true"
                       background layout="prev, pager, next" :total="totals1">
        </el-pagination>
      </div>
    </div>
  <!--  新增材料弹窗-->
    <div>
      <el-dialog
          v-model="dialogNewMaterial"
          title="新增材料"
          width="60%"
          :before-close="handleCloseNewMaterial">
        <div>
          <div style="display: flex;margin-bottom: 2%">
            <el-tag style="margin-right: 2%">材料名称:</el-tag>
            <el-input v-model="material_new_name" placeholder="材料名称" clearable style="width: 25%;"/>
          </div>
          <div style="display: flex;margin-bottom: 2%">
            <el-tag style="margin-right: 2%">规格型号:</el-tag>
            <el-input v-model="material_model" placeholder="规格型号" clearable style="width: 25%;" />
          </div>
          <div style="display: flex;margin-bottom: 2%">
            <el-tag style="margin-right: 2%">计量单位:</el-tag>
            <el-input v-model="material_unit" placeholder="计量单位" clearable style="width: 25%;" />
          </div>
          <div style="display: flex;margin-bottom: 2%">
            <el-tag style="margin-right: 2%">材料分类:</el-tag>
            <el-input v-model="material_type" placeholder="材料分类" clearable style="width: 25%;" />
          </div>
          <div style="display: flex;margin-bottom: 2%">
            <el-tag style="margin-right: 2%">材料备注:</el-tag>
            <el-input v-model="material_remarks" placeholder="材料备注" clearable style="width: 25%;" />
          </div>
          <div style="display: flex">
            <el-tag style="margin-right: 2%">当前库存:</el-tag>
            <el-input v-model="material_num" placeholder="当前库存" clearable style="width: 25%;" />
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="add_material_yes()">添加</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--  修改材料弹窗-->
    <div>
      <el-dialog
          v-model="dialogUpdateMaterial"
          title="修改材料"
          width="60%"
          :before-close="handleCloseNewMaterial">
        <div>
          <div style="display: flex;margin-bottom: 2%">
            <el-tag style="margin-right: 2%">材料名称:</el-tag>
            <el-input v-model="material_new_name" placeholder="材料名称" clearable style="width: 25%;"/>
          </div>
          <div style="display: flex;margin-bottom: 2%">
            <el-tag style="margin-right: 2%">规格型号:</el-tag>
            <el-input v-model="material_model" placeholder="规格型号" clearable style="width: 25%;" />
          </div>
          <div style="display: flex;margin-bottom: 2%">
            <el-tag style="margin-right: 2%">计量单位:</el-tag>
            <el-input v-model="material_unit" placeholder="计量单位" clearable style="width: 25%;" />
          </div>
          <div style="display: flex;margin-bottom: 2%">
            <el-tag style="margin-right: 2%">材料分类:</el-tag>
            <el-input v-model="material_type" placeholder="材料分类" clearable style="width: 25%;" />
          </div>
          <div style="display: flex;margin-bottom: 2%">
            <el-tag style="margin-right: 2%">材料备注:</el-tag>
            <el-input v-model="material_remarks" placeholder="材料备注" clearable style="width: 25%;" />
          </div>
          <div style="display: flex">
            <el-tag style="margin-right: 2%">当前库存:</el-tag>
            <el-input v-model="material_num" placeholder="当前库存" clearable style="width: 25%;" />
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="update_material_yes()">修改</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {mapGetters} from "vuex";
import { ElMessage } from 'element-plus';
export default {
  name: "material_warehouse",
  components: {Header},
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
  },
  inject: ['reload'],
  data(){
    return{
      bdst: '',
      bdsts: [
        {
          value: '21',
          label: '2标一工区/5标六工区',
        },
        {
          value: '22',
          label: '2标二工区',
        },
        {
          value: '51',
          label: '5标一工区',
        },
        {
          value: '52',
          label: '5标二工区',
        },
        {
          value: '53',
          label: '5标三工区',
        },
        {
          value: '54',
          label: '5标四工区',
        },
        {
          value: '55',
          label: '5标五工区',
        },
        {
          value: '57',
          label: '5标七工区',
        }
      ],
      material_name: '',
      materials: [],
      pagenum: 1,
      totals: 0,
      pagenum1: 1,
      totals1: 0,
      first: true,
      changeBdst: '',
      dialogNewMaterial: false,
      material_new_name: '',
      material_model: '',
      material_unit: '',
      material_type: '',
      material_remarks: '',
      material_num: '',
      dialogUpdateMaterial: false,
      id: ''
    }
  },
  created() {
    this.getMaterialInfo();
  },
  methods: {
    getMaterialInfo() {
      let bdst;
      if (sessionStorage.getItem('add_material_bdst')){
        bdst = sessionStorage.getItem('add_material_bdst');
        this.bdst = bdst;
      }else {
        bdst = this.GET_BDST
      }
      this.axios.post('/materialInfoemation/getMaterialByBdstPc', (response) => {
        this.materials = response.obj.records;
        this.totals = response.obj.pages * 10;
      }, {
        bdst: bdst,
        pageCount: 1
      })
    },
    changeMaterial() {
      if (this.bdst === '') {
        this.changeBdst = this.GET_BDST;
      } else {
        this.changeBdst = this.bdst;
      }
      sessionStorage.setItem('add_material_bdst', this.changeBdst);
      this.first = false;
      if (this.material_name === '') {
        this.axios.post('/materialInfoemation/getMaterialByBdstPc', (response) => {
          this.materials = response.obj.records;
          this.totals1 = response.obj.pages * 10;
        }, {
          bdst: this.changeBdst,
          pageCount: 1
        })
      } else {
        this.axios.post('/materialInfoemation/getMaterialByNamePc', (response) => {
          this.materials = response.obj.records;
          this.totals1 = response.obj.pages * 10;
        }, {
          bdst: this.changeBdst,
          materialName: this.material_name,
          pageCount: 1
        })
      }
    },
    remove_material(item){
      this.axios.post('/materialInfoemation/removeById', (response) => {
        if (response.obj){
          ElMessage({
            message: '删除成功!',
            type: 'success',
          });
          this.reload();
        }
      },{
        id: item.id
      })
    },
    handleCloseNewMaterial(){
      this.dialogNewMaterial = false;
      this.dialogUpdateMaterial = false;
      this.material_new_name = '';
      this.material_model = '';
      this.material_unit = '';
      this.material_type = '';
      this.material_remarks = '';
      this.material_num = '';

    },
    add_material(){
      this.dialogNewMaterial = true;
    },
    add_material_yes(){
      let bdst;
      if (this.bdst === '' && this.GET_BDST === '1'){
        ElMessage.error("请先选择工区!");
      }else {
        if (this.GET_BDST === '1') {
          bdst = this.bdst;
        } else {
          bdst = this.GET_BDST
        }
        this.axios.post('/materialInfoemation/saveMaterial', (response) => {
          if (response.obj){
            ElMessage({
              message: '新增成功!',
              type: 'success',
            });
            this.reload();
          }
        },{
          material_name: this.material_new_name,
          material_model: this.material_model,
          material_unit: this.material_unit,
          material_type: this.material_type,
          material_remarks: this.material_remarks,
          material_num: this.material_num,
          bdst: bdst
        })
      }
    },
    update_material(item){
      this.dialogUpdateMaterial = true;
      this.material_new_name = item.materialName;
      this.material_model = item.materialModel;
      this.material_unit = item.materialUnit;
      this.material_type = item.materialType;
      this.material_remarks = item.materialRemarks;
      this.material_num = item.materialNum.split(' ')[0];
      this.id = item.id;
    },
    update_material_yes(){
      this.axios.post('/materialInfoemation/updateById', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功!',
            type: 'success',
          });
          this.reload();
        }
      },{
        material_name: this.material_new_name,
        material_model: this.material_model,
        material_unit: this.material_unit,
        material_type: this.material_type,
        material_remarks: this.material_remarks,
        material_num: this.material_num,
        id: this.id
      })
    }
  },
  watch: {
    pagenum(){
      this.axios.post('/materialInfoemation/getMaterialByBdstPc', (response) => {
        this.materials = response.obj.records;
        this.totals = response.obj.pages * 10;
      },{
        bdst: this.GET_BDST,
        pageCount: this.pagenum
      })
    },
    pagenum1(){
      this.axios.post('/materialInfoemation/getMaterialByNamePc', (response) => {
        this.materials = response.obj.records;
        this.totals1 = response.obj.pages * 10;
      },{
        bdst: this.changeBdst,
        materialName: this.material_name,
        pageCount: this.pagenum1
      })

    }
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
.ware_div{
  margin-bottom: 2%;
}
</style>
