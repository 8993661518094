<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">系统管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
  <!--  基础信息管理-->
    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="材料信息" name="a"><Material_info></Material_info></el-tab-pane>
        <el-tab-pane label="供应单位" name="b"><Ware_info></Ware_info></el-tab-pane>
        <el-tab-pane label="使用单位" name="c"><Issue_info></Issue_info></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import Material_info from "@/views/material/admin/material_info";
import Issue_info from "@/views/material/admin/issue_info";
import Ware_info from "@/views/material/admin/ware_info";

export default {
  name: "material_manage",
  components: {Header, Material_info, Issue_info, Ware_info},
  data(){
    return{
      activeName: 'a'
    }
  },
  created() {
    if (sessionStorage.getItem('material_names')){
      this.activeName = sessionStorage.getItem('material_names');
    }
  },
  methods:{
    handleClick(tab, event){
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      sessionStorage.setItem('material_names', tab.props.name)
    },
    reall(){
      sessionStorage.removeItem('add_material_bdst');
      sessionStorage.removeItem('ware_source_bdst');
    },
  }
}
</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}
#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>