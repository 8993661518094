<template>
  <div>
    <!--  材料信息-->
    <div style="width: 100%;margin:  auto;min-height: 80vh;background-color: #FFFFFF">
      <div style=";padding-top: 0.5%">
        <div v-if="GET_BDST === '1'">
          <el-tag effect="dark" size="large" style="margin-right: 0.5%"> 选择工区</el-tag>
          <el-select v-model="source_bdst" class="m-2" placeholder="请选择工区" size="small" style="width: 15%"
                     @change="changeSource()">
            <el-option
                v-for="item in bdsts"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </div>
        <div style="margin-top: 1%;display: flex">
          <el-tag effect="dark" size="large" style="margin-right: 0.5%"> 单位名称</el-tag>
          <el-input v-model="ware_source_name" placeholder="请输入单位名称" size="small" style="width: 15%"
                    @input="changeSource()"/>
          <el-button type="primary" size="small" style="margin-left: 5%" @click="add_ware_source()">新增用料单位
          </el-button>
        </div>
      </div>
      <!--  材料列表-->
      <div style="padding-top: 0.5%">
        <el-table :data="sources" style="width: 100%">
          <el-table-column prop="bdst" label="所属工区" width="180"/>
          <el-table-column prop="sourceName" label="单位名称" width="280"/>
          <el-table-column prop="sourceCode" label="统一社会信用代码" width="280"/>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="primary" @click="update_source(scope.row)"> 修改</el-button>
              <el-button type="danger" @click="remove_source(scope.row)"> 删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--  新增使用单位弹窗-->
      <div>
        <el-dialog
            v-model="dialogNewSource"
            title="新增使用单位"
            width="60%"
            :before-close="handleCloseNewSource">
          <div>
            <div style="display: flex;margin-bottom: 2%">
              <el-tag style="margin-right: 2%">用&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;料&nbsp;&nbsp;&nbsp;&nbsp;单&nbsp;&nbsp;&nbsp;&nbsp;位:</el-tag>
              <el-input v-model="new_source_name" placeholder="用料单位" clearable style="width: 25%;"/>
            </div>
            <div style="display: flex;margin-bottom: 2%">
              <el-tag style="margin-right: 2%">统一社会信用代码:</el-tag>
              <el-input v-model="source_code" placeholder="统一社会信用代码" clearable style="width: 25%;"/>
            </div>
          </div>
          <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="add_source_yes()">添加</el-button>
          </span>
          </template>
        </el-dialog>
      </div>
      <!--  修改使用单位弹窗-->
      <div>
        <el-dialog
            v-model="diaUpdate"
            title="修改使用单位"
            width="60%"
            :before-close="handleCloseNewSource">
          <div>
            <div style="display: flex;margin-bottom: 2%">
              <el-tag style="margin-right: 2%">单&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位&nbsp;&nbsp;&nbsp;&nbsp;名&nbsp;&nbsp;&nbsp;&nbsp;称:</el-tag>
              <el-input v-model="new_source_name" placeholder="单位名称" clearable style="width: 25%;"/>
            </div>
            <div style="display: flex;margin-bottom: 2%">
              <el-tag style="margin-right: 2%">统一社会信用代码:</el-tag>
              <el-input v-model="source_code" placeholder="统一社会信用代码" clearable style="width: 25%;"/>
            </div>
          </div>
          <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="update_source_yes()">修改</el-button>
          </span>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/views/header";
import {mapGetters} from "vuex";
import {ElMessage} from 'element-plus';

export default {
  name: "issue_info",
  components: {Header},
  computed: {
    ...mapGetters(['GET_BDST']),
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_USERID']),
  },
  inject: ['reload'],
  data() {
    return {
      source_bdst: '',
      bdsts: [
        {
          value: '21',
          label: '2标一工区/5标六工区',
        },
        {
          value: '22',
          label: '2标二工区',
        },
        {
          value: '51',
          label: '5标一工区',
        },
        {
          value: '52',
          label: '5标二工区',
        },
        {
          value: '53',
          label: '5标三工区',
        },
        {
          value: '54',
          label: '5标四工区',
        },
        {
          value: '55',
          label: '5标五工区',
        },
        {
          value: '57',
          label: '5标七工区',
        }
      ],
      ware_source_name: '',
      sources: [],
      pagenum: 1,
      totals: 0,
      pagenum1: 1,
      totals1: 0,
      first: true,
      changeBdst: '',
      dialogNewSource: false,
      new_source_name: '',
      source_code: '',
      diaUpdate: false
    }
  },
  created() {
    this.getMaterialInfo();
  },
  methods: {
    getMaterialInfo() {
      let bdst;
      if (sessionStorage.getItem('issue_source_bdst')) {
        bdst = sessionStorage.getItem('issue_source_bdst');
        this.bdst = bdst;
      } else {
        bdst = this.GET_BDST
      }
      this.axios.post('/materialSource/getIssueSourceByBdst', (response) => {
        this.sources = response.obj;
      }, {
        bdst: bdst
      })
    },
    changeSource() {
      if (this.source_bdst === '') {
        this.changeBdst = this.GET_BDST;
      } else {
        this.changeBdst = this.source_bdst;
      }
      sessionStorage.setItem('issue_source_bdst', this.changeBdst);
      this.first = false;
      if (this.ware_source_name === '') {
        this.axios.post('/materialSource/getIssueSourceByBdst', (response) => {
          this.sources = response.obj;
        }, {
          bdst: this.changeBdst
        })
      } else {
        this.axios.post('/materialSource/getIssueSourceByBdst', (response) => {
          this.sources = response.obj;
        }, {
          bdst: this.changeBdst,
          source: this.ware_source_name
        })
      }
    },
    remove_source(item) {
      this.axios.post('/materialSource/removeSource', (response) => {
        if (response.obj) {
          ElMessage({
            message: '删除成功!',
            type: 'success',
          });
          this.reload();
        }
      }, {
        id: item.id,
        flag: 1
      })
    },
    handleCloseNewSource() {
      this.dialogNewSource = false;
      this.new_source_name = '';
      this.source_code = '';

    },
    add_ware_source() {
      this.dialogNewSource = true;
    },
    add_source_yes() {
      let bdst;
      if (this.source_bdst === '' && this.GET_BDST === '1') {
        ElMessage.error("请先选择工区!");
      } else {
        if (this.GET_BDST === '1') {
          bdst = this.source_bdst;
        } else {
          bdst = this.GET_BDST
        }
        this.axios.post('/materialSource/saveSource', (response) => {
          if (response.obj) {
            ElMessage({
              message: '新增成功!',
              type: 'success',
            });
            this.reload();
          }
        }, {
          source_name: this.new_source_name,
          source_code: this.source_code,
          flag: 1,
          bdst: bdst
        })
      }
    },
    update_source(item){
      this.new_source_name = item.sourceName;
      this.source_code = item.sourceCode;
      this.id = item.id;
      this.diaUpdate = true;
    },
    update_source_yes(item){
      console.log(item)
      this.axios.post("/materialSource/updataById", (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功!',
            type: 'success',
          });
          this.reload();
        }
      },{
        id: this.id,
        source_name: this.new_source_name,
        source_code: this.source_code
      })
    }
  }
}
</script>

<style scoped>
#admindiv1 {
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin: 10px auto 30px;
  justify-content: space-between;
}

.ware_div {
  margin-bottom: 2%;
}
</style>
